html {
  font-size: 62.5%;
}
body {
  font-size: $base-font-size;
  font-family: $fontbase;
}
h1 {
  font-size: 2.4rem;
  margin-bottom: 1em;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.2rem
}
h4 {
  font-size: 1.2rem;
}
a {
  color: $red;
  &:hover, &:active, &:focus {
    color: $grey;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
}