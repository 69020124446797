table.newapplicants {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-name {
    width: 15%;
  }
  .col-ref {
    width: 10%;
  }
  .col-position {
    width: 25%;
  }
  .col-mobile {
    width: 15%;
  }
  .col-email {
    width: 25%;
  }
  .col-applicantnr {
    width: 8%;
  }
  .col-delete {
    width: 2%;
  }
}
