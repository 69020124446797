html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}
body {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
