.applicant {
  .info {
    position: absolute;
    top: 0;
    right: 0;
  }
  .links {
    text-align: right;
  }
  .cvlinked {
    margin: 2em 0;
  }
}

table.applicants {
  .col-select {
    width: 5%;
  }
  .col-name {
    width: 23%;
  }
  .col-email {
    width: 25%;
  }
  .col-applnr {
    width: 12%;
  }
  .col-type {
    width: 15%;
  }
  .col-cv {
    width: 10%;
  }
  .col-history {
    width: 10%;
  }
}

table.languages {
  tbody {
    td {
      border: none;
    }
  }
}