.notice, .warning {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 3px;
}
.notice {
  color: rgb(86,101,26);
  background-color: rgb(230,239,194);
}
.warning {
  color: rgb(151,22,27);
  background-color: color($red l(80%));
}