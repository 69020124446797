.search {
  #cvs_sent, #cv_linked, #cv_linked_history {
    label {
      display: none;
    }
    select {
      float: left;
      width: 79%;
    }
    input {
      width: 20%;
    }

  }
  input[type='radio'] {
    height: 100%;
    line-height: 2.5em;
  }
  .extra {
    display: none;
  }
  #more {
    float: right;
    clear: fix;
  }
}

