.admindetails {
  .detaillink {
    cursor: pointer;
    margin: 0.5em 0;
  }
  .collapsed {
    display: none;
    padding: 1em;
    background-color: $grey-light;
  }
}