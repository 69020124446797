table.tasklist {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-datedue {
    width: 10%;
  }
  .col-body {
    width: 80%;
  }
  .col-status {
    width: 5%;
  }
  .col-edit {
    width: 5%;
  }
}
table.tasksapplicants, table.taskscontacts {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-name {
    width: 15%;
  }
  .col-datedue {
    width: 10%;
  }
  .col-body {
    width: 65%;
  }
  .col-status {
    width: 5%;
  }
  .col-edit {
    width: 5%;
  }
}
