.l-nav {
  background-color: $red;
  min-height: 2.5em;
}
.container {
  a {
    color: white;
    font-weight: 700;
  }
}

ul.menu {
  li {
    float: left;
    margin: 0.5em 1em 0.5em 0;
  }
}
ul.adminmenu {
  float: left;
  li {
    margin: 0.5em 1em 0.5em 0;
  }
}
ul.logout {
  float:right;
  li {
    margin: 0.5em 0;
  }
}