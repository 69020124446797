section.missions {
  ul {
    display: flex;
    li {
      margin-right: 1em;
    }
  }
}
table.missions {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-ref {
    width: 5%;
  }
  .col-position {
    width: 40%;
  }
  .col-client {
    width: 37%;
  }
  .col-status {
    width: 10%;
  }
  .col-date {
    width: 8%;
  }
}