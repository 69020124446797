section {
  position: relative;
  margin-bottom: 3em;
  clear: fix;
}
.col-left, .col-right {
  width: 48%;
}
.col-left {
  float: left;
}
.col-right {
  float: right;
}