.tabs {
  width: 100%;
}
.tab-links {
  display: flex;
  border-bottom: 1px solid $grey;
  li {
    margin: 0 0.5rem 0 0;
    a {
      color: color(white blackness(10%));
      padding: 0.75em 1em;
      background-color: color($grey whiteness(80%));
      border-top-left-radius: .4rem;
      border-top-right-radius: .4rem;
      display: block;
      &:hover {
        text-decoration: none;
        background-color: color($grey whiteness(60%));
      }
    }
    &.active a {
      color: white;
      font-weight: 600;
      background-color: $grey;
    }
  }
}
.tab-content {
  margin-top: 2em;
  .tab {
    display: none;
    &.active {
      display: block;
    }
  }
}
