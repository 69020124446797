table.clients {
  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .col-name {
    width: 25%;
  }
  .col-postalcode {
    width: 10%;
  }
  .col-town {
    width: 30%;
  }
  .col-website {
    width: 30%;
  }
}