/* bitters forms */
fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: block;
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
}

input,
select {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size;
}

input[type="text"], input[type="password"], select {
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  font-family: $base-font-family;
  font-size: $base-font-size;
  margin-bottom: $small-spacing;
  padding: calc($base-spacing / 3);
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }
}
/* end bitters forms */

form {
  margin-bottom: 1em;
}

textarea {
  resize: vertical;
}
input[type="text"], input[type="password"], select {
  &:focus {
    box-shadow: none;
  }
}
input[type="text"], input[type="password"] {
  padding: calc($base-spacing / 3 + .1rem);
  padding-left: calc($base-spacing / 3 + .4rem);
  padding-right: calc($base-spacing / 3 + .4rem);
}

[type="search"] {
  appearance: none;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: calc($small-spacing / 2);
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $base-spacing;
  max-width: 100%;
  width: auto;
}

select {
  /*
  margin-bottom: 0.75em;
  */
}
input[type="text"], input[type="password"], select {
  float: right;
  margin-bottom: 0;
  width: 60%;
  font-family: $fontbase;
  &.fullwidth {
    width: 100%;
  }
}
label {
  line-height: 2.5em;
  float: left;
  margin: 0;
  &.label-radio {
    font-weight: 400;
    margin-right: 0.5em;
    float: right;
  }
  &.hidden {
    display: none;
  }
  &.no-float {
    float: none;
  }
  &.header {
    background-color: $grey-light;
    border-radius: $base-border-radius;
    padding-left: 1em;
  }
}
input[type="checkbox"] {
  width: 2em;
  float: none;
}
input[type="radio"], input[type="checkbox"] {
  margin-top: -1px;
  vertical-align: middle;
}
.txt {
  display: inline-block;
  padding: 0.584em 0 1em 0;
  margin-bottom: 0;
}
.form-group {
  clear: both;
  width: 100%;
  display: inline-block;
  min-height: 2.667em;
  &.align-right {
    text-align: right;
  }
  h3 {
    float: left;
    line-height: 2.5em;
    margin: 0;
  }
}

.dropdown {
  margin: 0;
  dd, dt {
    margin: 0;
    padding: 0;
  }
  dd {
    position: relative;
    ul {
      z-index:1;
      background-color: white;
      border: 1px solid $red;
      display: none;
      left: 0;
      padding: 0.5em;
      position: absolute;
      top: 2px;
      width: 100%;
      list-style: none;
      height: 150px;
      overflow: auto;
      label {
        float: none;
        font-weight: 400;
        display: inline;
      }
    }
  }
  dt {
    background-color: $red;
    border-radius: 3px;
    display: block;
    padding: 0 0.5em;
    overflow: hidden;
    border: 0;
    width: 100%;
    cursor: pointer;
    span {
      color: white;
    }
  }
  ul {
    margin: -1px 0 0 0;
  }
}